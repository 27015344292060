import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pic from "../Media/requestser.png";
import { Alert, Button, Grid, Stack, useMediaQuery } from "@mui/material";
import TextFieldComp from "../Components/TextFieldComp";
import { Container } from "@mui/system";
import pic2 from "../Media/attach.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Checkbox from "@mui/material/Checkbox";
import {  useNavigate } from "react-router-dom";
import im from "../Media/Vector2.png";
import im2 from "../Media/Group.png";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";
import CustomTextField from "../Components/CustomTextField";

export default function RequestService() {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:700px)");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [id, setId] = useState("");
  const [gender, setGender] = useState("");
  const [genderList, setGenderList] = useState([{id :"male" , 'title':"Male"}, {id :"female" , 'title':"Female"}]);
  const [birthday, setBirthday] = useState("");
  const [neededService, setNeededService] = useState('');
  const [neededServicelist, setNeededServiceList] = useState([]);
  const [attachement, setAttachement] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [secondphone, setSecondPhone] = useState("");
  const [check, setCheck] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [open, setOpen] = useState(true);
  const [alerting, setAlerting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [diagnostic, setDiagnostic] = useState('')
  const [diagnosticArray, setDiagnosticArray] = useState([
    {id:'Heart valve disorders' ,title:'Heart valve disorders'},
    {id:'Heart failure' ,title:'Heart failure'},
    {id:'Congenital heart defects' ,title:'Congenital heart defects'},
    {id:'Pericardial diseases' ,title:'Pericardial diseases'},
    {id:'Cardiomyopathy' ,title:'Cardiomyopathy'},
    {id:'Blood clots or masses' ,title:'Blood clots or masses'},
    {id:'Pulmonary hypertension' ,title:'Pulmonary hypertension'},
    {id:'Aortic diseases' ,title:'Aortic diseases'},
    {id:'Heart attacks' ,title:'Heart attacks'},
    {id:'Atrial fibrillation' ,title:'Atrial fibrillation'},
    {id:'Valvular regurgitation' ,title:'Valvular regurgitation'},
    {id:'Arrhythmias' ,title:'Arrhythmias'},
    {id:'Palpitations' ,title:'Palpitations'},
    {id:'Dizziness or Syncope' ,title:'Dizziness or Syncope'},
    {id:'Silent ischemia' ,title:'Silent ischemia'},
    {id:'Post-heart attack monitoring' ,title:'Post-heart attack monitoring'},
    {id:'Stroke' ,title:'Stroke'},
    {id:'Effectiveness of treatments' ,title:'Effectiveness of treatments'},
    {id:'Pacemaker' ,title:'Pacemaker'},
    {id:'Conduction abnormalities' ,title:'Conduction abnormalities'},
    {id:'Bradycardia' ,title:'Bradycardia'},
    {id:'Tachycardia' ,title:'Tachycardia'},
  ])

  const navigate = useNavigate();
  let service_id ;
  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}services-title`, {
          headers: {
            "Accept-Language": lang,
          },
        });
  
        const services = res.data.data;
  
        setNeededServiceList(services);
  
        const url = window.location.href;
        service_id = Number(url.split("/").pop()); 
  
        const selectedService = services.find(service => service.id === service_id);
  
        if (selectedService) {
          setNeededService(selectedService.id); 
        }
  
        setOpen(false);
      } catch (error) {
        console.error("Error fetching services:", error);
        setOpen(false);
      }
    };
  
    fetchData();
  }, []);
  

  const handleSend =() =>{
    if(name && lastname && id && gender && neededService && birthday && email && phone && check && diagnostic){
      const formData = new FormData();
      formData.append("first_name", name);
      formData.append("last_name", lastname);
      formData.append("id_number", id);
      formData.append("gender", gender);
      formData.append("service_id", neededService);
      formData.append("birthdate", birthday);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("second_phone", secondphone);
      formData.append("file", attachement);
      formData.append("diagnostics", diagnostic);

 
      
    axios.post(`${process.env.REACT_APP_API_URL}patient-form`,formData, {
      headers: {
        "Accept-Language": lang,
      },
    }).then(
      res=>{
        if(res.data.status === true){
          setOpenSuccess(true)
          setTimeout(() => {
            navigate('/')
            }, 2100);
          setAlerting(false)
        }
    }
    )
    }
    else{
      setAlerting(true)
    }
  }


  return (
    <div style={{marginTop:'100px'}}>
            {open && <LoadingPage open={open} />}

     {!open && <> <div
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          height: matches ?'400px' : "300px",
        }}
      >
        <img
          src={pic}
          style={{
            height: matches ?'400px' : "300px",
            objectFit: "cover",
            width: "100%",
          }}
        />
        <div className="overLay"></div>
        <p className="sections-text">{t("Request Service")}</p>
      </div>
      <Container
        sx={{
          mt: "50px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          position: "relative",
        }}
        maxWidth="md"
      >
        {matches && (
          <>
            <img
              src={im}
              style={{
                position: "absolute",
                top: matches ? "0px" : "300px",
                right: lang === 'en' && "-50px",
                    left : lang === 'ar' && '-50px'
              }}
            />
            <img
              src={im2}
              style={{
                position: "absolute",
                top: matches ? "72%" : "300px",
                right: lang === 'en' && "-50px",
                    left : lang === 'ar' && '-50px'
              }}
            />
          </>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"First Name"}
              onChange={setName}
              value={name}
              h={"50px"}
              width={"80%"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"Last Name"}
              onChange={setLastName}
              value={lastname}
              h={"50px"}
              width={"80%"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"ID Number"}
              onChange={setId}
              value={id}
              h={"50px"}
              width={"80%"}
              type={'number'}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"Gender"}
              onChange={setGender}
              value={gender}
              h={"50px"}
              width={"80%"}
              data={genderList}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"Needed Service"}
              onChange={setNeededService}
              value={neededService}
              h={"50px"}
              width={"80%"}
              data={neededServicelist}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"Birthday"}
              onChange={setBirthday}
              value={birthday}
              h={"50px"}
              width={"80%"}
              type={"date"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <CustomTextField
              label={"Diagnostic"}
              onChange={setDiagnostic}
              value={diagnostic}
              h={"50px"}
              width={matches ? "90%" : "80%"}
              data={diagnosticArray}
            />
          </Grid>
        </Grid>
        {/* <p style={{ color: "#B0B0B0", fontSize: "15px" }}>
          Service Description ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna
        </p> */}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"Referral Attachment (Optional)"}
              onChange={setAttachement}
              value={attachement}
              h={"50px"}
              width={"80%"}
              type={"file"}
              end={pic2}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldComp
              label={"Email"}
              onChange={setEmail}
              value={email}
              h={"50px"}
              width={"80%"}
              type={"email"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{mt:'10px'}}>
          <Grid item xs={12} sm={6}>
            <label
              style={{
                color: "#3D3D3D",
                fontSize: "15px",
              }}
            >
              {t("Phone Number")}
            </label>
            <PhoneInput
              country={"il"}
              enableSearch={true}
              value={phone}
              onChange={setPhone}
              style={{ marginTop: "5px" }}
              inputStyle={{
                backgroundColor: "transparent",
                width: "80%",
                color: "black",
                fontSize: "13px",
                border: "2px solid #64C6C6",
                borderRadius: "10px",
                height: "50px",
                outline: "none",
                ":focus": {
                  border: "2px solid #64C6C6",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label
              style={{
                color: "#3D3D3D",
                fontSize: "15px",
              }}
            >
              {t("Secondary Phone Number")}
            </label>
            <PhoneInput
              style={{ marginTop: "5px" }}
              country={"il"}
              enableSearch={true}
              value={secondphone}
              onChange={setSecondPhone}
              inputStyle={{
                backgroundColor: "transparent",
                width: "80%",
                color: "black",
                fontSize: "13px",
                border: "2px solid #64C6C6",
                borderRadius: "10px",
                height: "50px",
                marginTop: "15px",
                outline: "none",
                zIndex:1,
                ":focus": {
                  border: "2px solid #64C6C6",
                },
              }}
            />
          </Grid>
        </Grid>

        <Stack>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: matches?"start":'start',
            flexWrap: "wrap",
          }}
        >
         <Checkbox
                  onChange={() => {
                    setCheck(!check);
                  }}
                  sx={{
                    color: "#64C6C6",
                    "&.Mui-checked": {
                      color: check ? "#64C6C6" : "#000000",
                    },
                    mt:'7px'
                  }}
                />
          <p style={{ color: "#3D3D3D", fontSize: "15px", marginTop:'15px' }}>
            {t('I agree to the')}{" "}
            <a
              onClick={() => {
                navigate("/privacypolicy");
              }}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {t('Terms & Conditions')}
            </a>
          </p>
        </div>
        <br />
          <p style={{ color: "#64C6C6", fontSize: "15px" }}>
            {t('Rest assured, your information is securely held and never shared with third parties')}
          </p>
          {alerting && (
                <Alert
                  severity="error"
                  sx={{
                    width: matches ? "420px" : "310px",
                    marginTop: "20px",
                    mb: "20px",
                  }}
                >
                  {t("All fields must be filled.")}
                </Alert>
              )}
          <Button
            sx={{
              backgroundImage: "linear-gradient(to right, #64C6C6 , #008080)",
              color: "white",
              borderRadius: "10px",
              textTransform: "none",
              fontSize: "15px",
              p: "5px",
              width: "250px",
              mt: lang === 'en' ? "50px" : '100px',
              mb: "50px",
            }}
            onClick={handleSend}
          >
            {t('Submit')}
          </Button>
        </Stack>
      </Container></>}

      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Your request has been successfully send."}
      />
      <ContactIcon />
    </div>
  );
}
