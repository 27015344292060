import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import pic1 from "../Media/Vector2.png";
import pic2 from "../Media/Group.png";
import bg from "../Media/Vector.png";
import {  useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import ContactIcon from "../Components/ContactIcon";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Booking from "../Components/Booking";

export default function InnerService() {
  const matches = useMediaQuery("(min-width:700px)");
  const windowHeight = React.useRef(window.innerHeight);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [information, setInformation] = useState({});
  const [open, setOpen] = useState(true);
  const [mainVideo, setMainVideo] = useState(''); 
  const url = window.location.href;
  let service_id = Number(url.split("/").pop()); 


  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}service-details/${service_id}`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setInformation(res.data.data);
        setOpen(false);
        // Set the first video as the main video
        if (res.data.data.media.length > 0 && res.data.data.media[0].file_type === "video/mp4") {
          setMainVideo(res.data.data.media[0].file);
        }
      });
  }, []);

  const handleMiniVideoClick = (videoUrl) => {
    setMainVideo(videoUrl);
  };

  return (
    <div style={{ marginTop: "100px" }}>
      {open && <LoadingPage open={open} />}

      {!open && (
        <>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              height: matches ? windowHeight.current : "300px",
            }}
          >
            <img
              src={information.file}
              style={{
                height: matches ? windowHeight.current : "300px",
                objectFit: "cover",
                width: "100%",
              }}
            />
            <div className="overLay"></div>
            <p className="sections-text">{information.title}</p>
          </div>

          <div style={{ position: "relative" }}>
            <img
              src={pic1}
              style={{
                position: "absolute",
                top: matches ? "50px" : "-55px",
                width: "100px",
                left: "40px",
              }}
            />

            <img
              src={pic2}
              style={{
                position: "absolute",
                top: matches ? "90%" : "90%",
                width: "100px",
                right: "40px",
              }}
            />
            {/* <Container
              maxWidth="sm"
              sx={{ pb: "50px", mt: "60px", textAlign: "start" }}
            > */}
              <p
                style={{ color: "#6D6D6D", textAlign: "start", padding:'15%' }}
                dangerouslySetInnerHTML={{ __html: information.description }}
              ></p>
            {/* </Container> */}

            <div
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "200px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {/* Display main video */}
              {mainVideo && (
                <video
                  src={mainVideo}
                  style={{
                    width: "80%",
                    objectFit: "contain",
                    borderRadius: "20px",
                    zIndex: 1,
                  }}
                  controls
                />
              )}

              {information.media.length > 1 && (
                <Stack
                  gap={1}
                  direction="row"
                  style={{
                    overflowX: "auto",
                    width: "80%",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      backgroundColor: "#f1f1f1d3",
                      borderRadius: "20px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#C5C0BB",
                      borderRadius: "20px",
                    },
                  }}
                >
                  {information.media.slice(1).map((m) => (
                    <video
                      key={m.file}
                      src={m.file}
                      style={{
                        width: "245px",
                        borderRadius: "20px",
                        marginBottom: "10px",
                        cursor: "pointer", // Add cursor to indicate clickability
                      }}
                      onClick={() => handleMiniVideoClick(m.file)} // Update main video when clicked
                      controls
                    />
                  ))}
                </Stack>
              )}
            </div>

            {information.technologies?.id && (
              <>
                <Stack
                  direction="row"
                  gap={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    mt: "50px",
                  }}
                >
                  <Typography sx={{ fontSize: "30px" }}>{t("Used")} </Typography>
                  <Typography sx={{ color: "#64c6c2", fontSize: "30px" }}>
                    {t("Technology")}
                  </Typography>
                </Stack>

                <Container maxWidth="md">
                  <Stack
                    direction="row"
                    gap={matches ? 10 : 5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      mt: "50px",
                    }}
                  >
                    <img
                      src={information.technologies.file}
                      style={{
                        height: "320px",
                        width: matches ? "300px" : "250px",
                        borderRadius: "20px",
                      }}
                    />

                    <Stack>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#64c6c2",
                          fontSize: "15px",
                          textAlign: matches ? "" : "center",
                        }}
                      >
                        {information.technologies.title}
                      </Typography>

                      <p
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: matches ? "450px" : "300px",
                          textAlign: matches ? "" : "center",
                        }}
                      >
                        {information.technologies.description}
                      </p>
                    </Stack>
                  </Stack>
                </Container>
              </>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  backgroundImage:
                    "linear-gradient(to right, #64C6C6 , #008080)",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "15px",
                  p: "5px",
                  minWidth: "250px",
                  mt: "50px",
                  mb: "50px",
                }}
                onClick={() => {
                  navigate("/requestservice/"+ service_id);
                }}
              >
                {t("Request Now")}
              </Button>
            </div>
          </div>
        </>
      )}
                       <Booking />

      <ContactIcon />
    </div>
  );
}
