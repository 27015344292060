import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Popper,
  Paper,
  ClickAwayListener,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

export default function CustomTextField({
  label,
  value,
  onChange,
  width,
  h,
  data,
}) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(""); 
  const [filteredData, setFilteredData] = useState(data); 
  const [anchorEl, setAnchorEl] = useState(null); 
  const [customValue, setCustomValue] = useState(""); 

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchValue(searchTerm);
    const filtered = data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const handleInputClick = (event) => {
    setAnchorEl(event.currentTarget); 
  };

  const handleMenuClose = () => {
    setAnchorEl(null); 
  };

  const handleOptionClick = (optionValue) => {
    onChange(optionValue); 
    handleMenuClose(); 
  };

  const handleCustomValueChange = (e) => {
    const newValue = e.target.value;
    setCustomValue(newValue);
    onChange(newValue); 
  };

  const isMenuOpen = Boolean(anchorEl);

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "gray",
              height: h,
              borderRadius: "10px",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #64C6C6",
                height: h,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #64C6C6",
                height: h,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #64C6C6",
                height: h,
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <div style={{ marginTop: "10px", position: "relative" }}>
      <label
        style={{
          color: "#3D3D3D",
          fontSize: "15px",
        }}
      >
        {t(label)}
      </label>
      <br />
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          value={value}
          onClick={handleInputClick}
          sx={{
            width: width,
            height: h,
            mt: "5px",
            borderRadius: "10px",
            backgroundColor: "transparent",
            ".MuiInputBase-root ": { height: h, borderRadius: "10px" },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span role="img" aria-label="dropdown">
                  ▼
                </span>
              </InputAdornment>
            ),
          }}
        />

        <Popper
          open={isMenuOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 1300, width:'49%' }}
        >
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Paper style={{ maxHeight: "200px", overflowY: "auto" }}>
              <div style={{ padding: "10px" }}>
                <TextField
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  fullWidth
                  sx={{
                    ".MuiInputBase-root ": { borderRadius: "10px" },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span role="img" aria-label="search">
                          🔍
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {filteredData.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => handleOptionClick(item.id)}
                >
                  {t(item.title)}
                </MenuItem>
              ))}

              <div style={{ padding: "10px" }}>
                <TextField
                  value={customValue}
                  onChange={handleCustomValueChange}
                  placeholder="Other..."
                  fullWidth
                  sx={{
                    ".MuiInputBase-root ": { borderRadius: "10px" },
                  }}
                />
              </div>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </ThemeProvider>
    </div>
  );
}
